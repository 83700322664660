import { useEffect, useState } from "react";
import QAService from "../../services/qa.service";
import { Card, Row, Col, Form } from "react-bootstrap";
import AudioPlayer from 'react-h5-audio-player';

export default function ExplanationComponent({ questionData }: any) {
    const [explanationData, setExplanationData] = useState<any>({});

    const getExplanationData = async (url: string): Promise<string | null> => {
        try {
            const res = await QAService.getEncMultimedia(url);
            if (res.status === 200) {
                return res.data.toString();
            } else {
                console.error("Failed to get data, status code:", res.status);
                return null;
            }
        } catch (error) {
            console.error("Error while fetching data:", error);
            return null;
        }
    };

    useEffect(() => {
        const fetchExplanationData = async () => {
            if (questionData && questionData.explanation) {
                const audioPromises = questionData.explanation.audio.map((url: any) => getExplanationData(url));
                const videoPromises = questionData.explanation.video.map((url: any) => getExplanationData(url));
                const imagePromises = questionData.explanation.image.map((url: any) => getExplanationData(url));
                const docPromises = questionData.explanation.doc.map((url: any) => getExplanationData(url));

                const audioData = await Promise.all(audioPromises);
                const videoData = await Promise.all(videoPromises);
                const imageData = await Promise.all(imagePromises);
                const docData = await Promise.all(docPromises);

                setExplanationData({
                    audio: audioData,
                    video: videoData,
                    image: imageData,
                    text: questionData.explanation.text,
                    doc: docData,
                });
            }
        };

        fetchExplanationData();
    }, [questionData]);

    return (
        <div className="w-100">
            {questionData && questionData.explanation &&
                <Card className="px-2 mt-3" style={{ backgroundColor: "#D0D5DD60", borderRadius: 8, outline: "none", border: "none" }}>
                    <Card.Body>
                        <Row>
                            <Col>
                                <h6>Explanations</h6>
                                <Row>
                                    {explanationData.audio && explanationData.audio.map((audioSrc: any, index: number) => (
                                        <Col md={4} key={index}>
                                            <Card className="px-2 mt-3" style={{ backgroundColor: "#D0D5DD80", borderRadius: 8, outline: "none", border: "none" }}>
                                                <Card.Body>
                                                    <Form.Label className="xcn-text-12 text-secondary">Audio {index + 1}</Form.Label><br />
                                                    <AudioPlayer
                                                        autoPlay
                                                        src={audioSrc || ""}
                                                        onPlay={e => console.log("onPlay")}
                                                        muted
                                                    />
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    ))}

                                    {explanationData.video && explanationData.video.map((videoSrc: any, index: number) => (
                                        <Col md={4} key={index}>
                                            <Card className="px-2 mt-3" style={{ backgroundColor: "#D0D5DD80", borderRadius: 8, outline: "none", border: "none" }}>
                                                <Card.Body>
                                                    <Form.Label className="xcn-text-12 text-secondary">Video {index + 1}</Form.Label><br />
                                                    <video width="100%" controls>
                                                        <source src={videoSrc || ""} type="video/mp4" />
                                                        Your browser does not support the video tag.
                                                    </video>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    ))}

                                    {explanationData.image && explanationData.image.map((imageSrc: any, index: number) => (
                                        <Col md={4} key={index}>
                                            <Card className="px-2 mt-3" style={{ backgroundColor: "#D0D5DD80", borderRadius: 8, outline: "none", border: "none" }}>
                                                <Card.Body>
                                                    <Form.Label className="xcn-text-12 text-secondary">Image {index + 1}</Form.Label><br />
                                                    <img src={imageSrc || ""} alt={`Question Image ${index + 1}`} style={{ width: '100%' }} />
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    ))}

                                    {explanationData.text && explanationData.text.map((text: any, index: number) => (
                                        <Col md={12} key={index}>
                                            <Card className="px-2 mt-3" style={{ backgroundColor: "#D0D5DD80", borderRadius: 8, outline: "none", border: "none" }}>
                                                <Card.Body>
                                                    <Form.Label className="xcn-text-12 text-secondary">Text {index + 1}</Form.Label><br />
                                                    <p>{text}</p>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    ))}

                                    {explanationData.doc && explanationData.doc.map((docSrc: any, index: number) => (
                                        <Col md={4} key={index}>
                                            <Card className="px-2 mt-3" style={{ width: 500, backgroundColor: "#D0D5DD80", borderRadius: 8, outline: "none", border: "none" }}>
                                                <Card.Body>
                                                    <Form.Label className="xcn-text-12 text-secondary">Document {index + 1}</Form.Label><br />
                                                    <embed src={docSrc} type="application/pdf" width="100%" height="400px" />
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            }
        </div>
    );
};