import { faEye, faTrash, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Alert, Card, Col, Form, Image, Row } from "react-bootstrap"
import { TranslatorService } from "../../services/translator.service"
import { useEffect, useState } from "react"
import PreviewModal from "../../Modal/preview.modal"
import AudioPlayer from 'react-h5-audio-player';

interface IMultimedia {
    allowed: any
}

export default function ViewMultimedia(props: IMultimedia) {


    const [encData, setEncData] = useState<any>()
    const [encVideo, setEncVideo] = useState<any>("");
    const [encAudio, setEncAudio] = useState<any>();

    const [attachmentsData, setAttachmentsData] = useState<any>();
    const [showPreviewModal, setShowPreviewModal] = useState<boolean>();


    const getEncMediaImage = async () => {
        await TranslatorService.getEncMultimedia(props.allowed.image).then((res => {
            if (res.status === 200) {
                setEncData(res.data);
            }
        }))
    }

    const getEncMediaVideo = async () => {
        await TranslatorService.getEncMultimedia(props.allowed.video).then((res => {
            if (res.status === 200) {
                setEncVideo(res.data.toString());
            }
        }))
    }

    const getEncMediaAudio = async () => {
        await TranslatorService.getEncMultimedia(props.allowed.audio).then((res => {
            if (res.status === 200) {
                setEncAudio(res.data);
            }
        }))
    }

    const getEncAttachmentsImage = async (link: string) => {
        await TranslatorService.getEncMultimedia(link).then((res => {
            if (res.status === 200) {
                setAttachmentsData(res.data);
            }
        }))
    }


    useEffect(() => {
        if (props?.allowed?.image) {
            getEncMediaImage();
        }
    }, [props?.allowed?.image])

    useEffect(() => {
        if (props?.allowed?.audio) {
            getEncMediaAudio();
        }
    }, [props?.allowed?.audio])

    useEffect(() => {
        if (props?.allowed?.video) {
            getEncMediaVideo();

        }
    }, [props?.allowed?.video])


    return (
        <>
            <Card className="reg-card box-shadow mt-3 mb-4">
                <Card.Body>
                    <Row>
                        <Col md={4}>
                            <Form.Label className="xcn-text-12 text-secondary">Question Image</Form.Label><br />
                            {props?.allowed?.image ?
                                <div className="mb-4">
                                    <Image src={encData && encData.split(";base64,")[1] ? encData : "data:image/jpeg;base64," + encData} className="w-100" />
                                </div> : <Alert variant="danger">
                                    <FontAwesomeIcon icon={faTriangleExclamation} />
                                    <span className="ms-3">No Image File Uploaded</span>
                                </Alert>}
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Card className="reg-card box-shadow mt-3 mb-4">
                <Card.Body>
                    <Row>
                        <Col md={4}>
                            <Form.Label className="xcn-text-12 text-secondary">Option Data</Form.Label><br />
                            <div>
                                {props.allowed?.options?.length > 0 ? props.allowed?.options?.map((option: any, index: number) => {
                                    return (
                                        <div className='fw-bold xcn-text-15'><span className="text-muted">Option {index + 1}. </span>{option.value}</div>
                                    )
                                }) : <Alert variant="danger">
                                    <FontAwesomeIcon icon={faTriangleExclamation} />
                                    <span className="ms-3">No Options Data Available</span>
                                </Alert>
                                }
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Card className="reg-card box-shadow mt-3 mb-4">
                <Card.Body>
                    <Row>
                        <Col md={4}>

                            <Form.Label className="xcn-text-12 text-secondary">Question Video</Form.Label><br />
                            {props?.allowed?.video && encVideo ?
                                <video width="320" height="240" controls>
                                    <source type="video/mp4" src={encVideo} />
                                    <source type="video/ogg" src={encVideo} />
                                </video> : <Alert variant="danger">
                                    <FontAwesomeIcon icon={faTriangleExclamation} />
                                    <span className="ms-3">No Video File Uploaded</span>
                                </Alert>}
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Card className="reg-card box-shadow mt-3 mb-4">
                <Card.Body>
                    <Row>
                        <Col md={4}>
                            <Form.Label className="xcn-text-12 text-secondary">Question Audio</Form.Label><br />
                            {props?.allowed?.audio && encAudio ?
                                <AudioPlayer
                                    autoPlay
                                    src={encAudio}
                                    onPlay={e => console.log("onPlay")}
                                    muted
                                /> : <Alert variant="danger">
                                    <FontAwesomeIcon icon={faTriangleExclamation} />
                                    <span className="ms-3">No Audio File Uploaded</span>
                                </Alert>}
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            <Card className="reg-card box-shadow mt-3 mb-4">
                <Card.Body>
                    <Row>
                        <Col md={4}>
                            <Form.Label className="xcn-text-12 text-secondary">Question Attachments</Form.Label><br />
                            {props?.allowed?.attachments && props?.allowed?.attachments?.length > 0 ? props?.allowed?.attachments?.map((data: any, index: number) => {
                                return (
                                    <>
                                        <div>
                                            <p className="text-muted xcn-text-10 fw-bold" >Image {index + 1} <FontAwesomeIcon icon={faEye} className="ms-2" onClick={() => { getEncAttachmentsImage(data); setShowPreviewModal(true) }} />
                                                {/* <span><FontAwesomeIcon icon={faTrash} className="ms-3 text-danger" onClick={() => handleDelete(index)} /></span> */}
                                            </p>
                                        </div>
                                    </>
                                )
                            }) : <Alert variant="danger">
                                <FontAwesomeIcon icon={faTriangleExclamation} />
                                <span className="ms-3">No Attachments File Uploaded</span>
                            </Alert>}

                            <PreviewModal
                                type="image"
                                show={showPreviewModal}
                                onHide={() => setShowPreviewModal(false)}
                                image={attachmentsData}
                            />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    )
}