import { faPlus, faMinus, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, FloatingLabel, Form, Row, Tab, Tabs } from 'react-bootstrap';
import toast from 'react-hot-toast';
import Select from 'react-select';
import { OptionTypeEnum } from '../../../enums/question.enum';
import PreviewModal from '../../../Modal/preview.modal';
import PublicService from '../../../services/public.service';
import QAService from '../../../services/qa.service';
import UploadService from '../../../services/upload.service';
import UserProfileService from '../../../services/userprofile.service';
import { BsEye } from 'react-icons/bs';
import PreviewImageModal from '../../../Modal/PreviewImage.modal';
import ComprehensionQuestion from './ComprehensionQuestion';
import Explanation from '../../../components/CustomComponent/Explanation';
import TextEditor from '../../../components/TextEditor/TextEditor';

export default function ManualUploadIndex() {

    const [questionTypes, setQuestionTypes] = useState<any[]>();
    const [subjects, setSubjects] = useState<any[]>();
    const [questionImagesPdf, setQuestionImagesPdf] = useState<any>()

    const [multimediaData, setMultimediaData] = useState<any>();
    const [selectedQuestionType, setSelectedQuestionType] = useState<string>();
    const [selectedQuestionSubject, setSelectedQuestionSubject] = useState<any>();
    const [selectedOptionData, setSelectedOptionData] = useState<any>({
        optionsData: []
    });
    const [languages, setLanguages] = useState<any[]>();
    const [selectedLanguage, setSelectedLanguage] = useState<any>();
    const [difficultyLevel, setDifficultyLevel] = useState<any[]>();
    const [selectedDifficultyLevel, setSelectedDifficultyLevel] = useState<any>();

    const [questionAttachments, setQuestionAttachements] = useState<any[]>([]);
    const [encData, setEncData] = useState<any>();
    const [showPreviewModal, setShowPreviewModal] = useState<boolean>();

    const [languageTabs, setLanguageTabs] = useState<any>();
    const [examCategory, setExamCategory] = useState<any>();
    const [selectExamCategory, setSelectExamCategory] = useState<any>();
    const [toggleQuestion, setToggleQuestion] = useState<boolean>(false);

    const [subTopicData, setSubTopicData] = useState<any>();
    const [topicsData, setTopicsData] = useState<any>();


    const [selectTopicId, setSelectTopicId] = useState<any>();
    const [selectSubTopics, setSelectSubTopics] = useState<any>();

    const [answerExplanation, setAnswerExplanation] = useState<any>([])
    const [comprehensionData, setComprehensionData] = useState<any>([{ questions: [] }]);

    const [compTheoryData, setCompTheoryData] = useState<any>([]);
    const [compInstructionData, setCompInstructionData] = useState<any>([]);
    const [compCorrectAnswer, setCompCorrectAnswer] = useState<any>([]);

    const [languageWiseQuestionData, setLanguageWiseQuestionData] = useState<any>([{ optionsData: ['', '', '', ''] }]);
    const [previewImage, setPreviewImage] = useState<string>('');
    const [wordProcessorText, setWordProcessorText] = useState<string>("");

    const [loading, setLoading] = useState<boolean>(false)

    const handleCorrectAnswer = (e: any, optionIndex: number, languageIndex: number) => {
        let currentLanguageWiseQuestionData = [...languageWiseQuestionData];
        if (currentLanguageWiseQuestionData[languageIndex]?.optionType === 'bullet') {
            currentLanguageWiseQuestionData[languageIndex].answers = e ? [optionIndex] : [];
        } else {
            const currentAnswers = currentLanguageWiseQuestionData[languageIndex].answers || [];

            if (e) {
                currentLanguageWiseQuestionData[languageIndex].answers = [...currentAnswers, optionIndex];
            } else {
                const updatedArray = currentAnswers.filter((item: number) => item !== optionIndex);
                currentLanguageWiseQuestionData[languageIndex].answers = updatedArray;
            }
        }
        setLanguageWiseQuestionData(currentLanguageWiseQuestionData);
    };



    const getAllQuestionTypes = async () => {
        await UserProfileService.getAllQuestionTypes().then(res => {
            if (res.status === 200) {
                const questiontypes = res.data.map((data: string) => {
                    return {
                        label: data,
                        value: data
                    }
                })
                setQuestionTypes(questiontypes)
            }
        })
    }






    const getAllSubjects = async () => {
        try {
            const res = await UserProfileService.getAllSubjects();
            if (res.status === 200) {
                const subjects = res.data.map((data: any) => {
                    return {
                        label: data.name,
                        value: data._id,
                    };
                });
                setSubjects(subjects);
            }
        } catch (e) {
            console.error(e);
        }
    };


    const getTopicsAndSubTopics = async (selectedSubjectId?: any, selectedTopicId?: any) => {
        try {
            const res = await UserProfileService.getAllSubjects();
            if (res.status === 200) {
                const selectedSubject = res.data.find((data: any) => data._id === selectedSubjectId);

                if (selectedSubject) {
                    const topics = selectedSubject.topics.map((topic: any) => ({
                        label: topic.name,
                        value: topic._id,
                    }));

                    if (selectedTopicId) {
                        const selectedTopic = selectedSubject.topics.find((topic: any) => topic._id === selectedTopicId);
                        const subTopics = selectedTopic ? selectedTopic.sub_topics.map((subTopic: any) => ({
                            label: subTopic.name,
                            value: subTopic._id,
                        })) : [];
                        setSubTopicData(subTopics);
                    }

                    setTopicsData(topics);
                }
            }
        } catch (e) {
            console.error(e);
        }
    };


    useEffect(() => {
        if (selectedQuestionSubject?.value || selectTopicId?.value) {
            getTopicsAndSubTopics(selectedQuestionSubject?.value, selectTopicId?.value);
        }

    }, [selectedQuestionSubject, selectTopicId])







    const getLanguage = async () => {
        await PublicService.getLanguage().then(res => {
            if (res.status === 200) {
                const language = Object.values(res.data).map((data: any) => {
                    return {
                        label: data,
                        value: data
                    }
                })
                setLanguages(language);
            }
        })
    }


    const getExamCategory = async () => {
        await PublicService.getExamCategory().then(res => {
            if (res.status === 200) {
                const category = Object.values(res.data).map((data: any) => {
                    return {
                        label: data.name,
                        value: data._id
                    }
                })
                setExamCategory(category);
            }
        })
    }





    const getDifficultyLevel = () => {
        let data = []
        for (let i = 1; i <= 10; i++) {
            data.push({
                label: i,
                value: i
            })
        }
        setDifficultyLevel(data);
    }

    const handleMultiMediaUpload = async (e: any, index: number) => {
        let form_data = new FormData;
        form_data.append('file', e.target.files[0]);

        toast.promise(UploadService.uploadMultimedia(form_data).then((res) => {
            if (res.status === 200) {
                let currentLanguageWiseQuestionData = languageWiseQuestionData;
                currentLanguageWiseQuestionData[index] = { ...currentLanguageWiseQuestionData[index], [e.target.name]: res.data }
                setLanguageWiseQuestionData(currentLanguageWiseQuestionData);
            }
        }), {
            loading: 'Uploading...',
            success: 'File Uploaded',
            error: 'Error while uploading file',
        });
    }


    const submitUpload = async () => {
        try {
            const formData = new FormData();
            formData.append('file', questionImagesPdf);

            const res = await UploadService.uploadImagesZip(formData);

            if (res.status === 200) {
                const link = document.createElement('a');
                link.href = res.data
                link.download = 'data.zip';
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                document.body.removeChild(link);

                toast.success("Zip file downnloaded");
            } else {
                throw new Error('Upload failed');
            }
        } catch (error) {
            toast.error("Something went wrong when uploading Multimedia");
        }
    };

    const handlePdfUpload = (e: any) => {
        setQuestionImagesPdf(e.target.files[0])
    }


    const handleQuestionAttachements = async (e: any, index: number) => {
        let form_data = new FormData;
        form_data.append('file', e.target.files[0]);

        toast.promise(UploadService.uploadMultimedia(form_data).then((res) => {
            if (res.status === 200) {
                let currentLanguageWiseQuestionData = [...languageWiseQuestionData]; // Make a copy to avoid mutating the original state
                const existingAttachments = currentLanguageWiseQuestionData[index]?.attachments || [];
                const updatedQuestionData = {
                    ...currentLanguageWiseQuestionData[index],
                    'attachments': [...existingAttachments, res.data],
                };
                currentLanguageWiseQuestionData[index] = updatedQuestionData;
                setLanguageWiseQuestionData(currentLanguageWiseQuestionData);
            }
        }), {
            loading: 'Uploading...',
            success: 'File Uploaded',
            error: 'Error while uploading file',
        });
    }


    const handleDelete = (attachementIndex: number, index: number) => {
        // setQuestionAttachements(arr => arr.filter((_: any, i: number) => i != index))
        let currentLanguageWiseQuestionData = [...languageWiseQuestionData];
        const existingAttachments = currentLanguageWiseQuestionData[index]?.attachments || [];
        const updatedQuestionData = {
            ...currentLanguageWiseQuestionData[index],
            'attachments': existingAttachments.filter((_: any, i: number) => i != attachementIndex),
        }
        currentLanguageWiseQuestionData[index] = updatedQuestionData;
        setLanguageWiseQuestionData(currentLanguageWiseQuestionData);
    }

    const getEncMediaImage = async (link: string) => {
        await QAService.getEncMultimedia(link).then((res => {
            if (res.status === 200) {
                setEncData(res.data);
            }
        }))
    }


    const handleOptionSelectionChange = (e: any, index: number) => {
        if (selectedQuestionType == "boolean") {
            let currentLanguageWiseQuestionData = languageWiseQuestionData;
            currentLanguageWiseQuestionData[index] = { ...currentLanguageWiseQuestionData[index], optionType: e.value, optionsData: ["True", "False"] };
            setLanguageWiseQuestionData(currentLanguageWiseQuestionData);
        }
        else if (selectedQuestionType == "mcq") {
            let currentLanguageWiseQuestionData = languageWiseQuestionData;
            currentLanguageWiseQuestionData[index] = { ...currentLanguageWiseQuestionData[index], optionType: e.value, optionsData: ['', '', '', ''] };
            setLanguageWiseQuestionData(currentLanguageWiseQuestionData);
        } else {
            let currentLanguageWiseQuestionData = languageWiseQuestionData;
            currentLanguageWiseQuestionData[index] = { ...currentLanguageWiseQuestionData[index], optionType: e.value, optionsData: [] };
            setLanguageWiseQuestionData(currentLanguageWiseQuestionData);
        }
    }

    const handleUpdateOptions = (e: any, optionIndex: number, languageIndex: number) => {
        let currentLanguageWiseQuestionData = [...languageWiseQuestionData];
        if (
            currentLanguageWiseQuestionData[languageIndex]?.optionsData &&
            optionIndex >= 0 &&
            optionIndex < currentLanguageWiseQuestionData[languageIndex].optionsData.length
        ) {
            currentLanguageWiseQuestionData[languageIndex].optionsData[optionIndex] = e.target.value;
            setLanguageWiseQuestionData(currentLanguageWiseQuestionData);
        } else {
            console.error("Invalid option index or optionsData is undefined");
        }
    }


    const addOption = (languageIndex: number) => {
        setLanguageWiseQuestionData((prevData: any) => {
            if (languageIndex < 0 || languageIndex >= prevData.length) {
                console.error("Invalid index:", languageIndex);
                return prevData;
            }

            const updatedData = prevData.map((item: any, idx: number) => {
                if (idx === languageIndex) {
                    return {
                        ...item,
                        optionsData: item.optionsData ? [...item.optionsData, ""] : [""]
                    };
                }
                return item;
            });

            return updatedData;
        });
    };


    const deleteOption = (languageIndex: number) => {
        setLanguageWiseQuestionData((prevData: any) => {
            if (languageIndex < 0 || languageIndex >= prevData.length) {
                console.error("Invalid index:", languageIndex);
                return prevData;
            }

            const updatedData = prevData.map((item: any, idx: number) => {
                if (idx === languageIndex && item.optionsData.length > 0) {
                    return {
                        ...item,
                        optionsData: item.optionsData.slice(0, -1)
                    };
                }
                return item;
            });

            return updatedData;
        });
    };





    function handleQuestionDataChange(e: any, index: number) {
        let currentLanguageWiseQuestionData = [...languageWiseQuestionData];
        currentLanguageWiseQuestionData[index] = { ...currentLanguageWiseQuestionData[index], 'questionText': e.target.value };
        setLanguageWiseQuestionData(currentLanguageWiseQuestionData);
    }

    function handleWordTextChange(e: any, index: number) {
        let currentLanguageWiseQuestionData = [...languageWiseQuestionData];
        currentLanguageWiseQuestionData[index] = { ...currentLanguageWiseQuestionData[index], 'wordText': e };
        setLanguageWiseQuestionData(currentLanguageWiseQuestionData);
    }


    useEffect(() => {
        getAllQuestionTypes()
        getAllSubjects()
        getLanguage()
        getDifficultyLevel()
        getExamCategory()
    }, [])



    useEffect(() => {
        if (selectedQuestionType === 'mcq') {
            setSelectedOptionData({ optionType: "bullet" });

            if (languageTabs !== undefined && languageTabs < languageWiseQuestionData.length) {
                let updatedData = [...languageWiseQuestionData];
                updatedData[languageTabs] = {
                    ...updatedData[languageTabs],
                    optionsData: ['', '', '', '']
                };
                setLanguageWiseQuestionData(updatedData);
            }
        }
    }, [selectedQuestionType]);

    useEffect(() => {
        if (languageTabs !== undefined && languageTabs < languageWiseQuestionData.length) {
            let updatedData = [...languageWiseQuestionData];
            if (!updatedData[languageTabs]?.optionsData) {
                updatedData[languageTabs] = {
                    ...updatedData[languageTabs],
                    optionsData: ['', '', '', '']
                };
                setLanguageWiseQuestionData(updatedData);
            }
        }
    }, [languageTabs]);





    function clearState() {
        setLanguageWiseQuestionData([]);
        setSelectedQuestionType("")
    }


    const resetFormFields = () => {
        setSelectedLanguage([]);
        setLanguageWiseQuestionData([]);
        setAnswerExplanation({ explanation: [] });
        // setSelectedOptionData({ optionsData: [] });
        // setSelectTopicId(null);
        // setSelectedQuestionSubject(null);
        // setSelectSubTopics([]);
        // setSelectExamCategory(null);
        // setSelectedQuestionType("");
        // setSelectedDifficultyLevel(null);
    }

    const uploadQuestion = async () => {
        let questionData: any = [];

        selectedLanguage && selectedLanguage.forEach((lang: any, index: number) => {
            let data: any = {
                language: lang.label,
                options: languageWiseQuestionData[index].optionsData.map((data: any) => { return { value: data } }),
                image: languageWiseQuestionData[index]?.image || undefined,
                video: languageWiseQuestionData[index]?.video || undefined,
                audio: languageWiseQuestionData[index]?.audio || undefined,
                attachments: languageWiseQuestionData[index]?.attachments || undefined,
                answers: languageWiseQuestionData[index]?.answers || undefined,
                questionText: languageWiseQuestionData[index]?.questionText || undefined,
                explanation: answerExplanation?.explanation?.length > 0 ? answerExplanation?.explanation[index] : {}
            }
            if (lang?.wordText) {
                data['wordText'] = lang?.wordText
            }
            questionData.push(data);
        });

        const data = {
            difficulty: selectedDifficultyLevel.value,
            questionType: selectedQuestionType,
            topicId: selectTopicId.value,
            subjectId: selectedQuestionSubject.value,
            optionType: selectedOptionData.optionType,
            subTopicId: selectSubTopics.value,
            examCategory: selectExamCategory.value,
            questionData: questionData
        }

        setLoading(true);

        await UploadService.uploadQuestion(data).then((res) => {
            if (res.status === 200) {
                toast.success("Question Upload Successful.");
                resetFormFields();
            }
        }).catch(err => {
            toast.error(`${err.response.data}`);
        })
        setLoading(false)
    }



    const uploadComprehensionQuestion = async () => {


        const compData: any[] = [];

        if (selectedLanguage) {
            for (let index = 0; index < selectedLanguage.length; index++) {
                const lang = selectedLanguage[index];
                const questions = [];

                if (comprehensionData && comprehensionData[index] && comprehensionData[index].questions) {
                    for (let qIndex = 0; qIndex < comprehensionData[index].questions.length; qIndex++) {
                        const question = comprehensionData[index].questions[qIndex];
                        questions.push({
                            ...question,
                            answers: compCorrectAnswer && compCorrectAnswer[index] ? compCorrectAnswer[index].answers : []
                        });
                    }
                }

                const data: any = {
                    language: lang.label,
                    instructions: compInstructionData && compInstructionData[index]?.text,
                    theory: compTheoryData && compTheoryData[index],
                    questions: questions,
                    explanation: comprehensionData?.explanation?.length > 0 ? comprehensionData?.explanation[index] : {}
                };

                compData.push(data);
            }
        }



        const data = {
            difficulty: selectedDifficultyLevel.value,
            questionType: selectedQuestionType,
            topicId: selectTopicId.value,
            subjectId: selectedQuestionSubject.value,
            optionType: "checkbox",
            subTopicId: selectSubTopics.value,
            examCategory: selectExamCategory.value,
            compData: compData
        }

        setLoading(true)

        await UploadService.uploadComprehensionQuestion(data).then((res) => {
            if (res.status === 200) {
                toast.success("Question Upload Successful.");
                resetFormFields();
                setCompInstructionData([]);
                setCompTheoryData([]);
                setCompCorrectAnswer([]);
                setComprehensionData([]);
            }
        }).catch(err => {
            toast.error(`${err.response.data}`);
        })

        setLoading(false)

    }



    const renderOptionsData = (index: number) => {
        const questionData = languageWiseQuestionData[index];
        if (!questionData) return null;

        return (
            <>
                <div className='d-flex justify-content-between align-items-center'>
                    <div></div>
                    <div>
                        {questionData.optionType !== "boolean" ? (
                            <>
                                <FontAwesomeIcon
                                    icon={faPlus}
                                    className='text-success me-2 xcn-link-pointer'
                                    size="sm"
                                    onClick={() => addOption(index)}
                                />
                                <FontAwesomeIcon
                                    icon={faMinus}
                                    className='text-danger xcn-link-pointer'
                                    size="sm"
                                    onClick={() => deleteOption(index)}
                                />
                            </>
                        ) : " "}
                    </div>
                </div>
                <div>
                    {questionData?.optionsData?.map((data: any, optionIndex: number) => (
                        <div className="d-flex" key={optionIndex}>
                            <div className="me-3">
                                <Form.Check
                                    className="fs-3"
                                    checked={questionData.answers?.includes(optionIndex)}
                                    onChange={(e: any) => handleCorrectAnswer(e.target.checked, optionIndex, index)}
                                />
                            </div>
                            <FloatingLabel
                                controlId={"option" + optionIndex}
                                label={"Option " + (optionIndex + 1)}
                                className="w-100 mb-3"
                            >
                                <Form.Control
                                    type="text"
                                    value={data}
                                    onChange={(e: any) => handleUpdateOptions(e, optionIndex, index)}
                                    disabled={selectedQuestionType === "boolean"}
                                />
                            </FloatingLabel>
                        </div>
                    ))}
                </div>
            </>
        );
    };



    const optionTypesData = [{
        value: OptionTypeEnum.CHECKBOX,
        label: "CheckBox"
    }, {
        value: OptionTypeEnum.BULLET,
        label: "Bullet"
    }]


    const renderOptionDataTypes = () => {
        switch (selectedQuestionType) {
            case "mcq": {
                return (
                    <Select
                        options={optionTypesData}
                        value={optionTypesData.find((option: any) => option.value === selectedOptionData.optionType)}
                        onChange={(e: any) => setSelectedOptionData({ ...selectedOptionData, optionType: e.value })}
                    />
                );
            }
            case "descriptive": {
                return (
                    <Select
                        value={[{
                            value: OptionTypeEnum.TEXTAREA,
                            label: "Text Area"
                        }]}
                        options={[{
                            value: OptionTypeEnum.TEXTAREA,
                            label: "Text Area"
                        }]}
                        onChange={(e: any) => setSelectedOptionData({ ...selectedOptionData, optionType: e.value })}
                    />
                );
            }
            case "comprehension":
            case "video":
            case "fill_in_the_blanks":
            case "audio": {
                return (
                    <Select
                        options={[
                            { value: OptionTypeEnum.TEXTAREA, label: "Text Area" },
                            { value: OptionTypeEnum.CHECKBOX, label: "CheckBox" },
                            { value: OptionTypeEnum.BULLET, label: "Bullet" }
                        ]}
                        value={[
                            { value: OptionTypeEnum.TEXTAREA, label: "Text Area" },
                            { value: OptionTypeEnum.CHECKBOX, label: "CheckBox" },
                            { value: OptionTypeEnum.BULLET, label: "Bullet" }
                        ].find((data) => data.value === selectedOptionData.optionType)}
                        onChange={(e: any) => setSelectedOptionData({ ...selectedOptionData, optionType: e.value })}
                    />
                );
            }
            case "boolean": {
                return (
                    <Select
                        options={[{
                            value: OptionTypeEnum.BOOLEAN,
                            label: "True/False"
                        }]}
                        onChange={(e: any) => setSelectedOptionData({ ...selectedOptionData, optionType: e.value })}
                    />
                );
            }
            default: {
                return null;
            }
        }
    };





    const renderOptionTypes = (index: number) => {
        switch (selectedQuestionType) {
            case "mcq": {
                return (
                    <Select
                        options={optionTypesData}
                        onChange={(e) => handleOptionSelectionChange(e, index)}
                    // value={optionTypesData.find((option: any) => option.value === selectedOptionData.optionType)}

                    />
                )
            }
            case "descriptive": {
                return (
                    <Select
                        options={[{
                            value: OptionTypeEnum.TEXTAREA,
                            label: "Text Area"
                        }]}
                        onChange={(e) => handleOptionSelectionChange(e, index)}
                    />
                )
            }
            case "comprehension":
            case "video":
            case "fill_in_the_blanks":
            case "audio": {
                return (
                    <Select
                        options={[{
                            value: OptionTypeEnum.TEXTAREA,
                            label: "Text Area"
                        }, {
                            value: OptionTypeEnum.CHECKBOX,
                            label: "CheckBox"
                        }, {
                            value: OptionTypeEnum.BULLET,
                            label: "Bullet"
                        }]}
                        onChange={(e) => handleOptionSelectionChange(e, index)}
                    />
                )
            }
            case "boolean": {
                return (
                    <Select
                        options={[{
                            value: OptionTypeEnum.BOOLEAN,
                            label: "True/False"
                        }]}
                        onChange={(e) => handleOptionSelectionChange(e, index)}
                    />
                )
            }
        }

    }



    return (
        <>
            <h5>Upload Tools</h5>

            <Card className="reg-card box-shadow">

                <Card.Body>
                    <Row>
                        <Col md={6} lg={3}>
                            <Form.Label className="xcn-text-12 text-secondary">Download Question Images</Form.Label><span className="text-danger fw-bold xcn-text-20">*</span>
                            <Form.Control type="file" name="file" accept=".pdf" onChange={handlePdfUpload} />
                            <Button variant="primary" size="sm" onClick={submitUpload} className="mt-2">Upload Questions File</Button>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            <h5 className='mt-3'>Manual Question Upload</h5>


            <Card className="reg-card box-shadow">


                <Card.Body>
                    <Row>
                        <Col md={6} lg={3}>
                            <Form.Label className="xcn-text-12 text-secondary">Question Type</Form.Label>
                            <Select
                                value={questionTypes?.find((data: any) => data.value === selectedQuestionType)}
                                options={questionTypes}
                                onChange={(e: any) => {
                                    setSelectedQuestionType(e.value)
                                    // setSelectedOptionData({ optionsData: [] })
                                }}

                            />
                        </Col>
                        <Col md={6} lg={3}>
                            <Form.Label className="xcn-text-12 text-secondary">Question Subject</Form.Label>
                            <Select
                                options={subjects}
                                onChange={e => setSelectedQuestionSubject(e)}
                            />
                        </Col>

                        <Col md={6} lg={3}>
                            <Form.Label className="xcn-text-12 text-secondary">Question Topic</Form.Label>
                            <Select
                                options={topicsData}
                                onChange={e => setSelectTopicId(e)}
                            />
                        </Col>

                        <Col md={6} lg={3}>
                            <Form.Label className="xcn-text-12 text-secondary">Question Sub-Topic</Form.Label>
                            <Select
                                options={subTopicData}
                                onChange={e => setSelectSubTopics(e)}
                            />
                        </Col>



                    </Row>
                </Card.Body>
            </Card>
            <Card className='mt-3'>
                <Card.Body>
                    <Row>
                        <Col md={6} lg={3}>
                            <Form.Label className="xcn-text-12 text-secondary">Select Option Type</Form.Label>
                            {renderOptionDataTypes()}
                        </Col>
                        <Col md={6} lg={3}>
                            <Form.Label className="xcn-text-12 text-secondary">Select Language</Form.Label>
                            <Select
                                value={selectedLanguage}
                                options={languages}
                                onChange={e => setSelectedLanguage(e)}
                                isMulti
                            />
                        </Col>

                        <Col md={6} lg={3}>
                            <Form.Label className="xcn-text-12 text-secondary">Select Exam Category</Form.Label>
                            <Select
                                options={examCategory}
                                onChange={e => setSelectExamCategory(e)}
                            />
                        </Col>
                        <Col md={6} lg={3}>
                            <Form.Label className="xcn-text-12 text-secondary">Select Question Difficulty Level</Form.Label>
                            <Select
                                options={difficultyLevel}
                                onChange={e => setSelectedDifficultyLevel(e)}
                            />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            {selectedLanguage && selectedLanguage?.length > 0 &&
                <Card className='mt-3'>
                    <Card.Body>
                        <Tabs
                            activeKey={languageTabs}
                            onSelect={(key: any) => setLanguageTabs(key)}
                            id="controlled-tab-example"
                        >
                            {selectedLanguage.map((lang: any, index: number) => {
                                return (
                                    <Tab eventKey={index} title={lang.label}>
                                        {selectedQuestionType === "comprehension" ? <ComprehensionQuestion
                                            index={index}
                                            comprehensionData={comprehensionData}
                                            setComprehensionData={setComprehensionData}
                                            compTheoryData={compTheoryData}
                                            setCompTheoryData={setCompTheoryData}
                                            compInstructionData={compInstructionData}
                                            setCompInstructionData={setCompInstructionData}
                                            compCorrectAnswer={compCorrectAnswer}
                                            setCompCorrectAnswer={setCompCorrectAnswer}
                                        /> :
                                            <>
                                                <h5 className="mt-3">Question Data</h5>
                                                <Card className="reg-card box-shadow">

                                                    <Card.Body>
                                                        {/* <Row>
                                                            <Col>
                                                                <div className="d-flex">
                                                                    <Form.Label className="ms-2 text-primary" style={{ fontSize: "500" }}>Upload Question Text</Form.Label>
                                                                    <Form.Check className="ms-2" onChange={(e: any) => setToggleQuestion(e.target.checked)} />
                                                                </div>
                                                            </Col>
                                                        </Row> */}
                                                        <Row>

                                                            <Tabs >
                                                                <Tab eventKey={0} title="Question Text">
                                                                    {/* {toggleQuestion && */}
                                                                    <Col md={6} lg={6} className='mb-2'>
                                                                        <Form.Label className="xcn-text-12 text-secondary">Question Text</Form.Label><span className="text-danger fw-bold xcn-text-20">*</span>
                                                                        <Form.Control as="textarea" rows={3} name='questionText' value={languageWiseQuestionData[index]?.questionText} onChange={(e: any) => handleQuestionDataChange(e, index)} />
                                                                    </Col>
                                                                    {/* } */}
                                                                </Tab>
                                                                <Tab eventKey={1} title="Question Image">
                                                                    <Col md={6} lg={6}>
                                                                        <Form.Label className="xcn-text-12 text-secondary">Question Image</Form.Label><span className="text-danger fw-bold xcn-text-20">*</span>
                                                                        {
                                                                            languageWiseQuestionData[index]?.image &&
                                                                            <BsEye onClick={() => setPreviewImage(languageWiseQuestionData[index]?.image)} />
                                                                        }

                                                                        <Form.Control type="file" name="image" accept="image/png, image/jpeg" onChange={(e: any) => handleMultiMediaUpload(e, index)} />
                                                                    </Col>
                                                                </Tab>
                                                                <Tab eventKey={2} title="Word Processor">
                                                                    <div className="mt-3">
                                                                        <TextEditor text={languageWiseQuestionData?.[index]?.wordText} setText={(e: any) => handleWordTextChange(e, index)} />
                                                                    </div>
                                                                </Tab>
                                                            </Tabs>

                                                            <Col md={6} lg={3}>
                                                                <Form.Label className="xcn-text-12 text-secondary">Question Video</Form.Label>
                                                                <Form.Control type="file" name="video" accept='video/*' onChange={(e: any) => handleMultiMediaUpload(e, index)} />
                                                            </Col>
                                                            <Col md={6} lg={3}>
                                                                <Form.Label className="xcn-text-12 text-secondary">Question Audio</Form.Label>
                                                                <Form.Control type="file" name="audio" accept="audio/*" onChange={(e: any) => handleMultiMediaUpload(e, index)} />
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>

                                                <h5 className="mt-3">Question Image Attachments</h5>
                                                <Card className="reg-card box-shadow">
                                                    <Card.Body>
                                                        <Row>
                                                            <Col md={6}>
                                                                <Form.Label className="xcn-text-12 text-secondary">Question Image Attachemnts</Form.Label>
                                                                <Form.Control type="file" name="image" accept="image/png, image/jpeg" onChange={(e) => handleQuestionAttachements(e, index)} />
                                                            </Col>
                                                            <Col md={6}>
                                                                <Form.Label className="xcn-text-12 text-secondary">Uploads</Form.Label>
                                                                {languageWiseQuestionData && languageWiseQuestionData[index]?.attachments?.length > 0 ? languageWiseQuestionData[index]?.attachments.map((attachment: any, attachementIndex: number) => {
                                                                    return (
                                                                        <div>
                                                                            <p className="text-muted xcn-text-10 fw-bold" >Image {attachementIndex + 1} <FontAwesomeIcon icon={faEye} className="ms-2" onClick={() => { getEncMediaImage(attachment); setShowPreviewModal(true) }} />
                                                                                <span><FontAwesomeIcon icon={faTrash} className="ms-3 text-danger" onClick={() => handleDelete(attachementIndex, index)} /></span>
                                                                            </p>
                                                                        </div>
                                                                    )
                                                                }) : <p className="xcn-text-12 text-secondary">No attachents uploaded</p>}
                                                                <PreviewModal
                                                                    type="image"
                                                                    show={showPreviewModal}
                                                                    onHide={() => setShowPreviewModal(false)}
                                                                    image={encData}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>



                                                <h5 className="mt-3">Options Data</h5>
                                                <Card className="reg-card box-shadow">
                                                    <Card.Body>
                                                        <div>
                                                            {
                                                                selectedQuestionType &&
                                                                <Row>
                                                                    {/* <Col md={3}>
                                                                        {renderOptionTypes(index)}
                                                                    </Col> */}
                                                                    <Col md={9}>
                                                                        {renderOptionsData(index)}
                                                                    </Col>
                                                                </Row>
                                                            }
                                                        </div>
                                                        <div className="mt-3">
                                                            <Explanation data={answerExplanation} setData={setAnswerExplanation} index={index} />
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </>
                                        }
                                    </Tab>
                                )
                            })}
                        </Tabs>
                        {selectedQuestionType === "comprehension" ?
                            <div className="d-flex justify-content-center align-items-center mt-3">
                                <Button variant="primary" size="sm" onClick={() => uploadComprehensionQuestion()} disabled={loading}>
                                    Add Comprehension Question
                                </Button>
                            </div> : <div className="d-flex justify-content-center align-items-center mt-3">
                                <Button variant="primary" size="sm" onClick={() => uploadQuestion()} disabled={loading}>
                                    Add Question
                                </Button>
                            </div>
                        }
                    </Card.Body>
                </Card>
            }
            <PreviewImageModal
                show={previewImage}
                handleClose={() => setPreviewImage('')}
            />
        </>
    )
}